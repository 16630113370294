.profileView {
    box-shadow: 0px 4px 5px #ddd !important;

    .starIcon {
        color: #E3B336 !important;
    }

    .reviews {
        font-size: 13px;
    }
}

.profileView.info {
    p {
        font-weight: 600;
    }
}

.radioButton {
    .radio1 {
        margin-right: 20px !important;
    }
}

.headerBorder {
    border-bottom: $border-bottom;

    p {
        color: $gray;
    }
}

.detail {
    display: flex;
    align-items: center;

    .detailImg {
        height: 150px;
    }

    .detailImg.sm {
        height: 110px;
    }

    .starIcon {
        color: #E3B336 !important;

        svg {
            font-size: 16px;
            margin: 5px 4px;
        }

        svg:first-child {
            margin-left: 0px;
        }
    }

    .right {
        padding-left: 20px;
    }
}

.detailed {
    // display: flex;
    // align-items: center;
    text-align: center;

    .detailImg {
        height: 150px;
    }

    .detailImg.sm {
        height: 110px;
    }

    .starIcon {
        color: #E3B336 !important;

        svg {
            font-size: 16px;
            margin: 5px 4px;
        }

        svg:first-child {
            margin-left: 0px;
        }
    }

    .right {
        padding-left: 20px;
    }
}

.detailSec {
    p {
        margin-left: 20px;
        margin-bottom: 0px;
    }

    h5 {
        margin-bottom: 4.5px;
    }
}

.card-box-shadow {
    box-shadow: 0px 4px 5px #ddd !important;
}

.border_bottom {
    border-bottom: $border-bottom;
}

@media (min-width: 992px) {
    .col-lg-2 {
        width: 19.6%;
    }
}

.btn:focus {
    outline: 0;
    box-shadow: none !important;
}

.bottom_div {
    position: absolute;
    padding-bottom: 22px;
    left: 48vw;
}

.locationInput {
    .input-group {
        border: 2px solid $primary
    }

    .input-group-text {
        padding: 10px;
        border: 0px !important;
    }


    input {
        padding-bottom: 6px;
        border: 0px !important;
    }
}

.custom_menu.dropdown-menu.show {
    // transform: translate(-130px, 27px) !important;
}

.ellipsisIcon span svg {
    font-size: 16px !important;
    color: $primary
}

.card-img-top {
    height: 100% !important;
}

.management_table {

    tbody tr td:nth-child(1),
    tbody tr td:nth-child(2) {
        white-space: nowrap;
        max-width: 200px !important;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.table td {
    font-size: 13px;
}

.cursorPointer {
    cursor: pointer !important;

    input {
        cursor: pointer !important;
    }
}

.table thead th {
    text-transform: none
}

// .invalid-feedback{
//     display: block !important;
// }

.pac-container {
    // display: none;
}