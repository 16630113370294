.introCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 12px;
  box-shadow: 0px 4px 5px #ddd !important;
  padding: 0px 0px 5px 5px;
  height: 100px;

  img {
    width: 80px !important;
    height: 90px;
    border-radius: 8px;
    margin-top: 5px;
    object-fit: cover;
  }
  .detailSection {
    padding-left: 20px !important;
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    

    .left {
      h3 {
        font-size: 18px;
        margin-top: 15px;
        margin-bottom: 0px;
        white-space: nowrap;
        max-width: 200px !important;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      h4 {
        font-size: 15px;
        margin: 0px;
        margin-bottom: 2px;
      }

      p {
        margin: 0;
        font-size: 12px;
        color: #66707c;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        span {
          color: #000;
        }
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      padding-bottom: 15px;
      border-radius: 0px 12px 12px 0px;

      span {
        svg {
          font-size: 18px;
          color: $primary;
        }
      }

      .starSpan {
        padding: 4px 10px;
        background-color: $primary;
        color: $white;
        border-radius: 0px 12px 0px 20px;
        font-size: 12px;

        svg {
          color: #e3b336;
          font-size: 12px;
        }
      }
    }
  }
}

@media (max-width: 1340px) and (min-width: 1024px) {
  .introCardParent.col-lg-4 {
    width: 50% !important;
  }
}

@media (max-width: 1023px) {
  .introCardParent.col-lg-4 {
    width: 100% !important;
  }
}

.selfCard {
  border-radius: 12px;
  box-shadow: 0px 4px 5px #ddd !important;

  .imgaSection {
    // display: flex;
    // justify-content: end;
    position: relative;

    .starSpan {
      padding: 4px 10px;
      background-color: $primary;
      color: $white;
      border-radius: 0px 12px 0px 20px;
      position: absolute;
      font-size: 12px;
      top: 0;
      right: 0;
      svg {
        color: #e3b336;
        font-size: 12px;
      }
    }

    img {
      height: 120px !important;
      width: 100%;
      flex-grow: 1;
      border-radius: 12px 12px 0px 0px;
      position: relative;
      object-fit: cover;
    }
  }

  .detailSection {
    padding: 10px !important;
    display: flex;
    justify-content: space-between;

    .left {
      h3 {
        font-size: 18px;
        margin: 0px;
      }

      h4 {
        font-size: 15px;
        margin: 0px;
        margin-bottom: 2px;
      }

      p {
        margin: 0;
        font-size: 12px;
        color: #66707c;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        span {
          color: #000;
        }
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      padding-bottom: 15px;
      border-radius: 0px 12px 12px 0px;

      span {
        svg {
          font-size: 18px;
          color: $primary;
        }
      }
    }
  }
}

.userCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 12px;
  box-shadow: 0px 4px 5px #ddd !important;

  img {
    height: 130px !important;
    width: 130px !important;
    border-radius: 12px 0px 0px 12px;
  }

  .detailSection {
    padding-left: 20px !important;
    display: flex;
    flex-grow: 2;
    justify-content: space-between;

    .left {
      h3 {
        font-size: 18px;
        margin-top: 15px;
        margin-bottom: 0px;
      }

      h4 {
        font-size: 15px;
        margin: 0px;
        margin-bottom: 2px;
      }

      p {
        margin: 0;
        font-size: 12px;
        color: #66707c;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        span {
          color: #000;
        }
      }
    }

    .starSpan {
      svg {
        color: #e3b336;
        font-size: 12px;
      }
    }

    // .right {
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: space-between;
    //     align-items: center;
    //     font-size: 12px;
    //     padding-bottom: 15px;
    //     border-radius: 0px 12px 12px 0px;

    //     span {
    //         svg {
    //             font-size: 18px;
    //             color: $primary;
    //         }
    //     }

    // }
  }
}

.applicantCard {
  // display: flex;
  // flex-direction: row;
  // justify-content: space-between;
text-align: center;

  img {
    height: 130px !important;
    width: 130px !important;
    border-radius: 12px 0px 0px 12px;
  }

  .detailSection {
    padding-left: 20px !important;
    // display: flex;
    flex-grow: 2;
    justify-content: space-between;
    text-align: start;

    .left {
      h3 {
        font-size: 18px;
        margin-top: 15px;
        margin-bottom: 0px;
      }

      h4 {
        font-size: 15px;
        margin: 0px;
        margin-bottom: 2px;
      }

      p {
        margin: 0;
        font-size: 12px;
        color: #66707c;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        span {
          color: #000;
        }
      }
    }

    .starSpan {
      svg {
        color: #e3b336;
        font-size: 12px;
      }
    }


  }
}
.cardShadow{
  border-radius: 12px;
  box-shadow: 0px 4px 5px #ddd !important;
}
.kep-login-facebook {
  background: yellow;
}
.fbIcon{
  // border-radius: 12px;
  // background-color: none;
  // padding: 7px 17px;
  border: none;
  color: blue;
  font-size: 16px;
  // border-color:white ;
  background: transparent;
}