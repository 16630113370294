.details-container {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid whitesmoke;
    padding: 20px;
    width: 100%;
    font-family: Arial, sans-serif;
    font-size: 18px;
    overflow: auto;
  }
  
  .details-header {
    padding: 20px;
    color: #09101D;
    font-size: 1rem;
    min-width: 800px;
  }
  
  .details-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .details-table th, .details-table td {
    text-align: left;
    padding: 12px;
  }
  
  /* @media (max-width: 768px) {
    .details-table th, .details-table td {
      display: block;
      width: 100%;
      text-align: left;
    }
  
    .details-table td {
      padding: 8px;
    }
  } */
  