.summary-card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 100%;
    font-family: Arial, sans-serif;
  }
  
  .summary-card .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f4ff;
    border-radius: 8px;
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
  }
  
  .summary-card .icon img {
    width: 24px;
    height: 24px;
  }
  
  .summary-card .details {
    display: flex;
    justify-content: space-between;
  }
  
  .summary-card .details .item {
    text-align: left;
  }
  
  .summary-card .details .item .label {
    color: #66707C;
    font-size: 16px;
  }
  
  .summary-card .details .item .value {
    font-size: 22px;
    color: #09101D;
    ;
    /* font-weight: bold; */
  }
  
  .summary-card .details .item .change {
    color: #519C66;
    font-size: 13px;
  }